import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { compose, withState } from 'recompose';
import FaCalendarO from 'react-icons/lib/fa/calendar-o';
import MdAccessTime from 'react-icons/lib/md/access-time';
import TiHomeOutline from 'react-icons/lib/ti/home-outline';
import MdStore from 'react-icons/lib/md/store';
import moment from 'moment';
import { times } from 'ramda';
import LockerIconImage from '../images/locker.png';
import { Layout, Centralized } from '../components/shared/layout';
import {
  Button, Dropdown, HeaderText, Page, TextArea,
} from '../components/shared';

const StyledDeliveryConfirmation = styled(Layout)`
`;

const date = moment().subtract('day', 1);
const dayItems = times(() => {
  date.add('day', 1);
  return ({ text: date.format('DD MMM, YYYY'), value: date.format('DD-MMM-YYYY') });
}, 7);
const timeItems = [
  '12am - 3am',
  '3am - 6pm',
  '6am - 9pm',
  '9am - 12pm',
  '12pm - 3pm',
  '3pm - 6pm',
  '6pm - 9pm',
  '9pm - 12am',
].map(item => ({ text: item, value: item }));

const LockerIcon = styled.img.attrs({
  src: LockerIconImage,
})``;

const locationItems = [
  { text: '1-2-1 Kudanminami, Chiyoda-ku 102-8688', icon: TiHomeOutline },
  { text: 'Select a Pudo station', icon: LockerIcon },
  { text: 'Select a Kobini location', icon: MdStore },
].map(item => ({ text: item.text, value: item.text, icon: item.icon }));

const DeliveryConfirmation = ({
  selectedDate,
  selectedTime,
  selectedLocation,
  setDate,
  setTime,
  setLocation,
}) => (
  <StyledDeliveryConfirmation>
    <Page>
      <Centralized>
        <HeaderText priority={2}>Hi Natsumi, Mecca Maxima has sent you a parcel</HeaderText>
        <p>Please help us get that parcel to you as soon as possible.</p>
        <p>When would you like to receive your delivery?</p>
        <div>
          <Dropdown
            items={dayItems}
            value={selectedDate}
            icon={<FaCalendarO />}
            onItemClick={item => setDate(item.value)}
          />
        </div>
        <div>
          <Dropdown
            items={timeItems}
            value={selectedTime}
            icon={<MdAccessTime />}
            onItemClick={item => setTime(item.value)}
          />
        </div>
        <p>How would you like to receive your parcel?</p>
        <div>
          <Dropdown
            items={locationItems}
            value={selectedLocation}
            icon={<TiHomeOutline />}
            onItemClick={item => setLocation(item.value)}
          />
        </div>
        <p>Note</p>
        <div>
          <TextArea placeholder="Hey when you arrive, my door is the blue door, don't worry about my dog, just buzz unit 1" />
        </div>
        <div>
          <Button onClick={() => navigate('/delivery-confirmed')}>Confirm</Button>
        </div>
      </Centralized>
    </Page>
  </StyledDeliveryConfirmation>
);

const EnhancedDeliveryConfirmation = compose(
  withState('selectedDate', 'setDate', moment().format('DD-MMM-YYYY')),
  withState('selectedTime', 'setTime', '9am - 12pm'),
  withState('selectedLocation', 'setLocation', '1-2-1 Kudanminami, Chiyoda-ku 102-8688'),
)(DeliveryConfirmation);

export default EnhancedDeliveryConfirmation;